html, body, * {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*background-color: #f2f4f7;*/
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiPickersBasePicker-pickerView {
    max-width: 500px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    border: 0;
    -webkit-text-fill-color: #676767 !important;
    -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s;
    background: -webkit-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 174, 255, 0.04) 50%,
            rgba(255, 255, 255, 0) 51%,
            rgba(0, 174, 255, 0.03) 100%
    );
}

/*react-elastic carousel*/

.rec-carousel {
    /*width: 95vw !important;*/
}

.rec-arrow-left,
.rec-arrow-right {
    color:var(--primary) !important;
    font-size: 2em !important;
    background-color: white !important;
    border-color: white !important;
    border: solid 0px white !important;
    box-shadow: none !important;
    border-radius: 0;
}
.grayScale 
{
filter: grayscale(1)
}
.rec-arrow:hover:enabled,
.rec-arrow:focus:enabled {
    color: var(--primary) !important;
}
.atenddisable div.rec-carousel .rec-arrow-left:disabled,.atenddisable div.rec-carousel .rec-arrow-right:disabled
{
    visibility: hidden !important;
}
/*For copy and alert functionality*/
.link-message {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px
}
.gapBetween
{
    margin-left: 15%;
}    
.copy-icon {
    cursor: pointer;
    color: var(--black);
    font-size: 20px !important;
}
.centerContent{
     display: flex;
     align-items: center;
     justify-content: center;     
}
.leftContent
{
      display: flex;
     align-items: center;
     justify-content: left;     
}
.alignColumn
{
    flex-direction: column;
    align-items: flex-start !important;
    gap: 5px;
}
.leftContent img{
     margin-right: 20px;
}
.centerContent img{
     margin-right: 20px;
}

.animate {
    animation: disappear 1.5s linear;
}

.copyAlert {
    display: flex;
    align-items: center;
    font-size: 20px;
    position: absolute;
    opacity: 0;
    right: -25px;
}

@keyframes disappear {
    10% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* checkbox-rect */
.checkbox-rect input[type="checkbox"] + label:before {
  content: "";  
}
.PhoneInput {
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.23);
    height: 40px;
    padding: 5px;
    border-radius: 10px;
}
.PhoneInputInput
{
    border: none !important;
    background: transparent;
}
/* checkbox-rect */