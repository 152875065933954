.App {
    text-align: center;
}

.react-tel-input {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: rgba(0, 0, 0, 0.54) !important;
    border: 1px solid #c5c5c5 !important;
    border-radius: 30px !important;
    display: flex !important;
}

.react-tel-input .form-control {
    border: none !important;
    background: none !important;
    color: #666 !important;
    font-size: 16px !important;
}

.react-tel-input .flag-dropdown {
    background-color: initial !important;
    border: none !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
    background-color: initial !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
    background: initial !important;
}

.react-tel-input .flag-dropdown.open {
    background: none !important;
}

.react-phone-validation-error {
    background: #ff00004d !important;
    border-radius: 35px !important;
}

.react-tel-input .country-list {
    position: fixed !important;
}

.main-loader {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 2;
    position: absolute;
    background: #ffffff85;
    justify-content: center;
}

.main-loader img {
    align-self: center;
}

.submit-btn {
    background-color: #2bb0cf !important;
}

.MuiCircularProgress-colorPrimary {
    color: #2bb0cf !important;
}

input:disabled {
    cursor: not-allowed !important;
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.26) !important;
}

input:disabled > * {
    cursor: not-allowed !important;
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.26) !important;
}

input[type="color"] {
	-webkit-appearance: none;
	border: none;
	height: 25px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
}
.spinner {
    width: 40px; /* Size of the spinner */
    height: 40px;
    border: 6px solid #ccc; /* Light gray background border */
    border-top: 6px solid #0532A1; /* Spinner color */
    border-radius: 50%; /* Makes it circular */
    animation: spin 1s linear infinite; /* Rotates infinitely */
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  